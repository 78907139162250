footer{
    position: relative;
    padding: $content-padding;
    background-color: $gray-blue;
    color: $black;

    .menu-footer a, .infos-footer li{
        color: $white !important;
    }

    .links-footer{
        a{
            color: $white !important;
        }
        .nav{
            li{
                color: $white !important;
                a{
                    color: $white !important;
                }
            }
        }
    }
}