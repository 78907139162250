$marine:  #1a4f96;
$marine-dark: #142b5a;
$purple: #7f00ff;
$redGrey: #746565;
$pink:    #d17ae8;
$pink-light:    #dc78da;
$red:     #eb3d03;
$orange:  #e4a031;
$yellow:  #ffc107;
$green:   #0e9375;
$teal:    #20c997;
$cyan:    #0dcaf0;
$taupe:   #483C32;
$sky-blue: #7fc3e8;
$gold:    #ab911d;
$gold-light:  #d3c378;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$gray: #666666;
$gray-blue: #333b46;
$black:    #000;

$primary:       $gray-blue;
$secondary:     $pink-light;
$tertiary:      $sky-blue;
$success:       $green;
$info:          $marine-dark;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$box-shadow:                  0 .3rem 1rem rgba($black, .5);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);

$border-white: 1px solid white;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "3x2": calc(2 / 3 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);