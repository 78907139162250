#topMain{
    padding: 0 1.5rem;
    background-color: $pink-light;
    color: white;

    a{
        color: white !important;
    }

    .row-history{
        .navbar-mobile{
            button{
                i{
                    color: white;
                    font-size: 2rem;
                }
            }
        }
    }

    .row-result{
        padding: 0 3rem;
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
        h2{
            font-family: "Montserrat", sans-serif;
        }
    }

    
}

.transaction-detail{
    #topMain{
        .row-history{
            #display-honoraires,
            #add-selection,
            #display-calculette{
                cursor: pointer;
            }
        }
        .row-result{
            padding: 1.5rem 3rem;
            background-color: #ededed;
        }
    }
}