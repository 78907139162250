#search{
    .search-form{
        margin-top: 25rem;
        @include media-breakpoint-down(xl) {
          margin-top: 15rem;
          margin-bottom: 12rem;
        }
        @include media-breakpoint-down(md) {
          margin-top: 10rem;
          margin-bottom: 2rem;
        }
        @include media-breakpoint-down(sm) {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
        .search-form-title{
          h2, p{
            text-shadow: 1px 2px 2px #fff;
          }
        }
        form{
          padding: 0.25rem;
          background-color: white;
          .input-group{
            span{
              background-color: $primary;
              color: white;
              line-height: 1;
              border: 1px solid white;
              border-radius: 0;
              padding: 1rem;
            }
            input{
              line-height: 1;
              border: 0;
              border-right: 1px solid rgb(231, 231, 231);
              border-radius: 0;
            }
            input::placeholder {
              color: rgb(231, 231, 231);
              font-size: 0.9em;
            }
          }
          .btn{
            border: 0;
            border-radius: 0;
            background-color: $pink-light;
            color: white;
            padding: 1rem;
            line-height: 1;
          }

        }
      }
}