#subMain{
    padding: $content-padding;
    background-color: $gray-100 !important;
    color: rgb(0, 0, 0);

    .card-horizontal-accueil{
        h3{
            text-align: center;
            margin-top: 4rem;
            margin-bottom: 2rem;
            font-size: 2.5rem;
        }
        .card{
            box-shadow: $box-shadow;
            margin-bottom: 2rem;
            .row{
                .col{
                    .card-body{
                        .card-title{
                            text-align: center;
                            i{
                                display: block;
                                margin: 1rem 0;
                                color: $secondary;
                            }
                        }
                    } 
                }
    
                .col.hover-zoom{
                    overflow: hidden;
                    img{
                        transform: scale(1) rotate(0);
                        filter: grayscale(0);
                        transition: all 1s;
                    }
                }
    
                .col.hover-zoom:hover{
                    cursor: pointer;
                    img{
                        transform: scale(1.2) rotate(-2deg);
                        filter: grayscale(90%);
                        transition: all 1s;
                    }
                }
    
                .col.hover-zoom.rotate-left:hover{
                    cursor: pointer;
                    img{
                        transform: scale(1.2) rotate(2deg);
                        filter: grayscale(90%);
                        transition: all 1s;
                    }
                }
            }
        }
    }

    .card-horizontal-accueil > div:nth-child(-n+2){
        padding-bottom: 4rem;
    }

    .card-accueil{
        padding: 3rem;
        @include media-breakpoint-only(xs) {
            padding: 0;
        }
        @include media-breakpoint-only(sm) {
            padding: 0;
        }
        .row{
            .col{
                text-align: center;
                padding: 2rem;
                @include media-breakpoint-only(xs) {
                    padding: 0 0 2rem 0;
                }
                @include media-breakpoint-only(sm) {
                    padding: 0 0 2rem 0;
                }
                .card{
                    box-shadow: $box-shadow;
                    .card-body{
                        padding: 0;
                        margin-bottom: 1rem;
                        i{
                            display: block;
                            margin: 1rem 0;
                            color: $primary;
                        }
                        h5{
                            font-size: 1.5rem;
                            text-align: center;
                            margin-bottom: 1rem;
                            color: white;
                            padding: 0.75rem;
                        }
                        .card-text{
                            padding: 0.75rem;
                            p{
                                font-size: 1.1rem;
                            }
                        }
                    }
                }
            }

            .col{
                h5{
                    background-color: $pink-light;
                }
            }

            .col:first-child,
            .col:last-child{
                h5{
                    background-color: $primary;
                }
            }
        }
    }

    .card-canalisations{
        margin-top: 5rem;
        margin-bottom: 5rem;
        > h3{
            margin-bottom: 2rem;
        }
        > .row{
            margin-bottom: 5rem;
        }
        > .row:first-child{
            .col{
                .card{
                    .card-body{
                        .card-title{
                            background-color: $primary;
                        }
                    }
                }
            }
        }
        > .row:last-child{
            .col{
                .card{
                    .card-body{
                        .card-title{
                            background-color: $primary;
                        }
                    }
                }
            }
        }
        > .row:nth-child(even){
            .col{
                .card{
                    .card-body{
                        .card-title{
                            background-color: $pink-light;
                        }
                    }
                }
            }
        }
        .row{
            .col{
                .card{
                    box-shadow: $box-shadow;
                    .card-body{
                        .card-title{
                            text-align: center;
                            font-weight: bold;
                            margin-bottom: 2rem;
                            padding: 0.5rem;
                            color: white;
                        }
                    }
                }
            }
        }

    }

    .card-deratisation{
        margin-top: 3rem;
        margin-bottom: 3rem;
        h3{
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
        .row{
            padding: 2rem;
            @include media-breakpoint-only(xs) {
                padding: 0;
            }
            @include media-breakpoint-only(sm) {
                padding: 0;
            }
            .col{
                padding: 2rem;
                @include media-breakpoint-only(xs) {
                    padding: 0 0 2rem 0;
                }
                @include media-breakpoint-only(sm) {
                    padding: 0 0 2rem 0;
                }
                .card{
                    box-shadow: $box-shadow;
                    .card-body{
                        padding: 0;
                        .card-title{
                            padding: 0.75rem;
                            text-align: center;
                            color: white;
                        }
                        .card-text{
                            padding: 0.75rem;
                        }
                    }
                }
            }

            .col:first-child{
                .card-title{
                    background-color: $primary;
                }
            }

            .col:last-child{
                .card-title{
                    background-color: $secondary;
                }
            }

            .col:nth-child(2){
                .card-title{
                    color: black;
                    background-color: #87ae3b;
                }
            }
        }
    }

    .galerie{
        padding: 0.25rem 0 5rem 0;
        .col{
            padding: 0.75rem;
            a{
                box-shadow: $box-shadow;
                display: block;
                border: 1px solid $white;
                cursor: pointer;
            }
            .card-img-overlay{
                top: inherit;
                p.description{
                    padding: 0.5rem;
                    text-align: center;
                    background-color: rgba($color: #ffffff, $alpha: 0.40);
                }
            }
        }

        .col.hover-zoom-left, .col.hover-zoom-right{
            overflow: hidden;
            img{
                transform: scale(1) rotate(0);
                transition: all 1s;
            }
        }

        .col.hover-zoom-left:hover{
            cursor: pointer;
            img{
                animation: zoom-in-zoom-out-gray 0.6s ease-in-out alternate;
            }
        }

        .col.hover-zoom-right:hover{
            cursor: pointer;
            img{
                animation: gelatine 0.6s ease-in-out alternate;
            }
        }

        @keyframes zoom-in-zoom-out-gray {
            0% {
              transform: scale(1, 1);
              filter: grayscale(70%);
            }
            40% {
              transform: scale(1.2, 1.2);
              filter: grayscale(90%);
            }
            50% {
              transform: scale(0.9, 0.9);
              filter: grayscale(90%);
            }
            70% {
              transform: scale(1.1, 1.1);
              filter: grayscale(90%);
            }
            90% {
              transform: scale(0.95, 0.95);
              filter: grayscale(90%);
            }
            100% {
              transform: scale(1, 1);
              filter: grayscale(70%);
            }
        }

        @keyframes zoom-in-zoom-out-sepia {
            0% {
                transform: scale(1, 1);
                filter: sepia(70%);
              }
              40% {
                transform: scale(0.98, 0.98);
                filter: sepia(90%);
              }
              50% {
                transform: scale(1.1, 1.1);
                filter: sepia(85%);
              }
              70% {
                transform: scale(0.99, 0.99);
                filter: sepia(50%);
              }
              90% {
                transform: scale(1.2, 1.2);
                filter: sepia(90%);
              }
              100% {
                transform: scale(1, 1);
                filter: sepia(70%);
              }
        }

        @keyframes gelatine {
            from, to { transform: scale(1, 1); filter: grayscale(70%);}
            25% { transform: scale(0.9, 1.1); filter: grayscale(90%); }
            50% { transform: scale(1.1, 0.9); filter: grayscale(80%); }
            75% { transform: scale(0.95, 1.05); filter: grayscale(75%); }
          }
    }

    .row-description{
        .row-main{
            h4{
                font-weight: bold;
                color: $primary;
                margin: 0;
            }
            p.ref{
                font-size: 0.9rem;
            }
            .prix{
                span{
                    display: inline;
                    background-color: $primary;
                    padding: 0.5rem 1rem;
                    font-weight: bold;
                    font-size: 1.3rem;
                    color: white;
                    box-shadow: $box-shadow;
                }
            }
            hr.separation{
                border: 0;
                border-top: 3px solid $gray-blue;
                overflow: visible;
                padding: 0;
                color: $gray-blue;
                text-align: center;
            }

            hr.separation::after {
                content: "§";
                display: inline-block;
                position: relative;
                top: -1em;
                font-size: 1.2em;
                padding: 0 0.25em;
                background: $gray-100;
            }
        }
    }
}