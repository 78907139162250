header{
    padding: 0;
    color: white;
    z-index: 1020;
    position: relative;
    a{
        color: white !important;
    }

    #navbar-top-mobile{
        background-color: $gray-200;
        position: inherit;
    }

    .main-menu{
        z-index: 1030;
        //background-color: $gray-200;
        background-color: white;
        border-bottom: 2px solid white;
        box-shadow: $box-shadow;
        position: fixed;
        width: 100%;
        button{
            font-size: 1.5rem;
            .bi{
                color: black;
            }
        }
        
        .navbar-brand{
            width: 30% !important;
            top: 0 !important;
            transition: 0.8s !important;
            img{
                width: 200px;
                transition: 0.8s !important;
            }
        }
        .nav-item{
            font-family: 'Montserrat', sans-serif;
            @include media-breakpoint-only(xs) { a { font-size: 1.3rem; } }
            @include media-breakpoint-only(sm) { a { font-size: 1.3rem; } }
            @include media-breakpoint-only(md) { a { font-size: 1rem; } }
            @include media-breakpoint-only(lg) { a { font-size: 1rem; } }
            @include media-breakpoint-only(xl) { a { font-size: 1rem; } }
            @include media-breakpoint-only(xxl) { a { font-size: 1.1rem; } }

            .nav-link{
                padding-bottom: 0;
                margin-bottom: 0.5rem;
                color: $primary !important;
                font-weight: 600;
                border-bottom: 3px solid $gray-200;
                transition: border-bottom 0.75s;
            }

            .nav-link:hover{
                border-bottom: 3px solid $pink; 
                transition: all 0.75s;
            }
        }

        .nav-item-transaction{
            .dropdown-menu {
                background-color: $primary;
                border: 0;
                border-radius: 0;
                .dropdown-item:hover{
                  background-color: $primary;
                }
              }
        }

        .nav-item.active{
            a{
               border-bottom: 3px solid $pink-light; 
               transition: all 0.75s;
            }
        }
    }

    .form-ref{
        input{
            border-radius: 0;
        }
        input::placeholder {
            color: rgb(231, 231, 231);
            font-size: 0.9em;
        }
        .btn-outline-success {
            color: #000;
            background-color: $secondary;
            border-color: rgb(231, 231, 231);
            border-radius: 0;
        }
        .btn-outline-success:hover {
            color: #000;
            background-color: $tertiary;
            border-color: rgb(231, 231, 231);
        }
    }

    .nav-transaction{
        z-index: 9;
        position: fixed;
        top: 105px;
        .row{
          padding: 0.25rem;
          margin: 1rem 0;
          background-color: $primary;
          .nav{
            .nav-item{
                font-family: 'Montserrat', sans-serif;
                a{
                    font-size: 1.1rem;
                    color: $gray-blue;
                }
            }
          }
          .btn{
            border: 0;
            border-radius: 0;
            background-color: $primary;
            color: white;
            padding: 1rem;
            line-height: 1;
          }
          
          .dropdown-menu {
            background-color: $pink-light;
            border: 0;
            border-radius: 0;
            .dropdown-item:hover{
              background-color: $primary;
            }
          }
        }
      }

    .move-up{
        margin-top: -105px;
        transition: .7s;
    }

    .move-down{
        margin-top: 0;
        transition: .7s;
    }

    .fixed-top{
        .navbar-brand{
            width: 20% !important;
            top: 45px !important;
            transition: 0.8s !important;
            img{
                width: 200px;
                transition: 0.8s !important;
            }
        }
    }

}

.transaction,
.transaction-detail{
  header{
    background-color: #eff1f0;
    height: 180px;
  }
}