#offcanvas{

    .offcanvas{
        background: $primary;
        .offcanvas-header{
            .offcanvas-title{
                color: white;
            }
            .btn-close{
                background-color: white !important;
                opacity: 1 !important;
            }
        }
    }
    
    #offcanvasMenu{
        .offcanvas-body{
            .nav{
                .nav-item {
                    .nav-link{
                        color: white;
                        font-size: 1.2rem;
                    }
                }
                .nav-item:hover {
                    background-color: $secondary;
                }
            }
        }
    }

    #offcanvasSearch{
        .offcanvas-body{
            form{
                h4{
                    color: white;
                }
            }
            .nav{
                label{
                    color: white;
                }
            }
        }
    }

    
}