#subshowcase{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    position: relative;
    padding: 9rem $content-padding $content-padding $content-padding;
    @include media-breakpoint-down(md) {
        padding: 10rem $content-padding $content-padding $content-padding;
    }
    @include media-breakpoint-down(sm) {
        padding: 10rem $content-padding $content-padding $content-padding;
    }

    #anchor{
        display: block;
        height: 2px;
        width: 100%;
    }
}