@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

html, body {
    width: 100%;
    height: 100%;
}

body{
    font-family: "Roboto";
    h1{
        position: absolute;
        top: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}

@mixin make-container($padding-x: $container-padding-x) {
    width: 100%;
    padding-right: $padding-x;
    padding-left: $padding-x;
    margin-right: auto;
    margin-left: auto;
}

// Usage
.full-container {
    @include make-container();
}

body.error{
    h1{
        text-align: center;
        position: inherit;
        overflow: inherit;
        width: auto;
        height: auto;
    }
}

.bi-icon {
    font-size: 2rem; 
    color: cornflowerblue;
}

/* Content Text */
.row-content-text{
    .col-title{
        h2{
            text-align: center;
            font-family: "Montserrat", sans-serif;
        }

        hr.title{
          border: 0;
          border-bottom: 3px solid $pink;
          width: 350px;
          margin: 0 auto 5rem auto;
          @include media-breakpoint-down(md) {
            margin: 0 auto;
          }
          @include media-breakpoint-down(sm) {
            width: auto;
        }
        }
    }
    .col-text{
        h2, p {
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
            font-weight: normal;
        }
    }
    .col-img{
        img{
            box-shadow: $box-shadow;
        }
    }
    .tags{
        text-align: center;
        span{
            padding: 0.75rem;
            margin: 0.5rem 0;
            background-color: $primary;
            color: white;
            display: inline-block;
            @include media-breakpoint-only(xs) { font-size: 1.2rem; }
            @include media-breakpoint-only(sm) { font-size: 1.2rem; }
            @include media-breakpoint-only(md) { font-size: 1.5rem; }
            @include media-breakpoint-only(lg) { font-size: 1.7rem; }
            @include media-breakpoint-only(xl) { font-size: 1.7rem; }
            @include media-breakpoint-only(xxl) { font-size: 1.7rem; }
        }
    }
    span.obligatoire{
      display: block;
      font-size: 0.9rem;
    }
}

.modal-open{
    #myModalPopup{
        .modal-content{
            background-image: url('/assets/images/bg-popup.jpg');
            background-repeat: no-repeat;
            min-height: 450px;
            .col-content{
                padding: 1rem;
                background-color: rgba($color: #ffffff, $alpha: 0.35);
                .card{
                    border: 0;
                }
            }
        }
        .modal-content.no-size{
            background-position: -10vw;
        }
    }
}

#honorairesModal{
  .modal-body{
    .row-honoraires{
      .col-head{
          .card{
              font-weight: bold;
          }
      }

      .col{
          border-bottom: 1px solid $gray-200;
          .row{
              .card{
                  text-align: center;
                  border-radius: 0;
                  border: 0;
                  background: none;
              }
          }
          
          .row:nth-child(odd){
              background-color: $gold-light;
          }

          .row:first-child{
              background-color: $primary;
              color: white;
          }
      }
  }
  }
}

#calculetteModal{
  .modal-body{
    .row-calculette{
      .col{
        .row{
          background-color: $gray-200;
          h3{
            span{
              display: block;
              margin: 0;
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}

/* custom select */
.custom-select {
    display: none;
  }
  
  .dropdown-container {
    position: relative;
    .dropdown-select {
      font-family: "Montserrat", sans-serif;
      z-index: 200;
      padding: 1rem;
      text-align: left;
      font-size: 1rem;
      line-height: 1;
      cursor: pointer;
      transition: background-color 0.2s ease;
      background-color: $primary;
      color: #ecf0f1;
    }
    .dropdown-select:before {
      float: right;
      transition: 0.3s ease;
    }
    .dropdown-select-ul {
      display: none;
      z-index: 100;
      position: absolute;
      width: 100%;
      height: 300px;
      max-height: 50rem;
      overflow: scroll;
      overflow-y: auto;
      overflow-x: hidden;
      text-align: left;
      border: 1px solid;
      border-top: none;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      padding-left: 0;
      background: #ededed;
      color: $primary;
      li {
        display: block;
        padding: 0.35rem 1.4rem;
        cursor: pointer;
        font-size: 0.9rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
      }
      li.selected,
      li.optgroup {
        cursor: default;
        font-family: "Montserrat", sans-serif;
      }
      li.selected {
        background: $primary;
        color: white;
        font-weight: 600;
      }
      li.optgroup {
        width: 92%;
        padding-right: 0.7rem;
        margin: 0 4%;
        border-bottom: 1px solid;
        font-size: 90%;
        text-align: right;
        background: #ecf0f1;
        color: #a1aab0;
        border-bottom-color: #bdc3c7;
      }
      li:hover,
      li:focus {
        background: $primary;
      }
    }
    .dropdown-select:hover,
    .dropdown-select:focus {
      background-color: $gray;
    }
  }
  .dropdown-container.active{
    .dropdown-select {
      background-color: $marine-dark;
    }
    .dropdown-select:before {
      transform: rotate(180deg);
    }
    .dropdown-select-ul {
      display: block;
      animation-fill-mode: both;
      animation-duration: 0.3s;
      animation-name: fadeIn;
    }
  }

  .no-js{
    .custom-select {
      display: block;
      .dropdown-select{
        display: none;
      }
      .dropdown-select-ul {
        display: none;
      }
    }
  }
