#utility{
    padding: ($content-padding * 2) $content-padding;
    @include media-breakpoint-down(sm) {
        padding: $content-padding 0;
      }
    background-color: $gray-200;
    color: rgb(0, 0, 0);

    a{
        color: white !important;
    }

    .row-annonces{
        .col-block{
            .carousel-selection,
            .carousel-prestige{
                box-shadow: $box-shadow;
                border: 1px solid white;
                h3{
                    font-family: "Montserrat", sans-serif;
                }
                .h3-trait{
                    border: 0;
                    border-bottom: 3px solid $gold;
                    margin: 0 3rem;
                }
                .card{
                    .card-body{
                        background-color: $primary;
                        h5, p{
                            color: white;
                        }
                    }
                    .overlay{
                        background-color: rgba(0,0,0,0.35);
                        opacity: 0;
                        transition:  all 0.5s;
                        a{
                            i{
                                font-size: 7rem;
                                color: rgba(255,255,255,0.35);
                            }
                        }
                    }
                }
                .card:hover{
                    .overlay{
                        opacity: 1;
                        transition:  all 0.5s;
                    }
                }
            }
            .btn-nos-offres{
                background-color: $pink-light;
                color: $primary;
                font-family: "Montserrat", sans-serif;
                font-size: 1.5rem;
                border-radius: 0;
            }
        }
    }

    .tns-ovh{
        padding: 5rem 0;
        .tns-inner{
            margin: 0;
            .slider-canalisations, .slider-deratisation{
                margin-top: 3rem;
                margin-bottom: 3rem;
                .item{
                    .card{
                        background: none;
                        border: 0;
                        .card-body{
                            a{
                                box-shadow: $box-shadow;
                                border: $border-white;
                                display: block;
                                cursor: pointer;
                            }
                            .no-modal{
                                box-shadow: $box-shadow;
                                border: $border-white;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    
}