#subUtility{
    padding: ($content-padding * 2) $content-padding;
    background-color: $primary;
    color: white;

    a{
        color: white !important;
    }

    .feature-logos{
        margin-top: 7rem;
    }

    .block-estimation{
        padding-top: 5rem;
        padding-bottom: 5rem;
        h3{
            font-family: "Montserrat", sans-serif;
            font-size: 2.5rem;
            i{
                display: block;
                margin: auto;
                font-size: 3rem;
            }
        }

        p.estimation{
            margin-bottom: 2rem;
            a{
                background-color: $pink-light;
                text-decoration: none;
                margin-top: 1.2rem;
                border-radius: 0;
                font-size: 1.2rem;
                width: 330px;
                @include media-breakpoint-down(sm) {
                    font-size: 1.1rem;
                    width: auto;
                }
            }
        }

        p.tel{
            span{
                display: block;
                margin: 0.75rem auto 0 auto;
                background-color: $pink-light;
                padding: 0.25rem 1rem;
                width: 330px;
                font-size: 1.5rem;
                @include media-breakpoint-down(sm) {
                    font-size: 1.4rem;
                    width: auto;
                }
            }
        }
    }
}