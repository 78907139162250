#main{
    padding: $content-padding;
    background-color: $white;
    color: rgb(0, 0, 0);

    a{
        color: white !important;
    }

    .row-listing{
        padding: 0 3rem;
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
        #searchForm{
            .custom-select{
                margin-bottom: 1rem;
            }
            .dropdown-select{
                background-color: white;
                color: $primary;
                border: 1px solid $primary;
                font-weight: 600;
            }
            .dropdown-select:hover,
            .dropdown-select:focus,
            .search-btn:hover {
                background-color: $gray-200;
            }
            .search-btn{
                background-color: white;
                color: $primary;
                border: 1px solid $primary;
                border-radius: 0;
            }
        }

        #refForm{
            #ref{
                border: 1px solid $primary;
                border-radius: 0;
            }
            .search-btn{
                background-color: white;
                color: $primary;
                border: 1px solid $primary;
                border-radius: 0;
            }
            .search-btn:hover {
                background-color: $gray-200;
            }
        }

        .nav-display{
            .nav-item{
                a{
                    background-color: $primary;
                    font-size: 1.2rem;
                    font-weight: bold;
                }
                a.disabled{
                    background-color: inherit;
                    color: $primary !important;
                    font-size: 1rem;
                    font-weight: inherit;
                }
                #limitForm{
                    width: 170px;
                    label{
                        font-size: 0.9rem;
                    }
                    select{
                        width: 50%;
                    }
                }
                .tri-prix{
                    border-radius: 0;
                }
            }
        }

        .row-annonces{
            .col-block{
                .card{
                    .card-body{
                        background-color: rgba(255,255,255,0.6);
                        .prix{
                            font-size: 1.1rem;
                            span{
                                background-color: $primary;
                                color: white;
                                font-weight: 600;
                                padding: 0.25rem 0.5rem;
                            }
                        }
                    }
                    .overlay{
                        background-color: rgba(0,0,0,0.35);
                        opacity: 0;
                        transition:  all 0.5s;
                        a{
                            i{
                                font-size: 7rem;
                                color: rgba(255,255,255,0.35);
                            }
                        }
                    }
                }
                .card:hover{
                    .overlay{
                        opacity: 1;
                        transition:  all 0.5s;
                    }
                }
            }
            .col-inline{
                .card{
                    .col-img{
                        .overlay{
                            background-color: rgba(0,0,0,0.35);
                            opacity: 0;
                            transition:  all 0.5s;
                            a{
                                padding-top: 20%;
                                i{
                                    font-size: 7rem;
                                    color: rgba(255,255,255,0.35);
                                }
                            }
                        }
                    }
                    .col-img:hover{
                        .overlay{
                            opacity: 1;
                            transition:  all 0.5s;
                        }
                    }
                    .col-text{
                        .card-body{
                            a{
                                color: $primary !important;
                            }
                            .prix{
                                font-size: 1.1rem;
                                span{
                                    background-color: $gray-blue;
                                    color: white;
                                    padding: 0.25rem 0.5rem;
                                }
                            }
                            .add-selection-item,
                            .del-selection-item{
                                cursor: pointer;
                            }
                        }
                    }
                }
                
            }
            .card{
                box-shadow: $box-shadow;
                border: 1px solid white;
            }
            .pagination{
                .page-item.active{
                    .page-link{
                        background-color: $secondary !important;
                    }
                }
                .page-item:hover{
                    .page-link{
                        background-color: $tertiary !important;
                    }
                }
            }
            .etiquette{
                background-color: $gold-light;
                color: $primary;
                font-weight: 400;
                padding: 0.5rem;
            }
        }
    }

    .row-detail{
        .hidden{
            opacity: 0.35;
            transition: 0.2s;
        }

        .reverseHidden{
            opacity: 1;
            transition: 0.2s;
        }

        #contact_detail{
            label{
                font-size: 0.9rem;
            }
            input,
            textarea{
                border-radius: 0;
                font-size: 0.9rem;
            }
            
        }
    }

    .feature-sur-mesure{
        .row{
            margin-top: 5rem;
            margin-bottom: 5rem;
            .col{        
                box-shadow: $box-shadow;
                border: 1px solid white;
                padding: 5rem 2rem !important;
                background-color: $primary;
                color: white;
                i{
                    display: block;
                    margin: 1rem auto;
                    width: 60px;
                    text-align: center;
                }
                h3{
                    font-size: 2.5rem;
                    text-align: center;
                }
                p{
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
        }
    }

    .row-honoraires{
        .col-head{
            .card{
                font-weight: bold;
            }
        }

        .col{
            border-bottom: 1px solid $gray-200;
            .row{
                .card{
                    text-align: center;
                    border-radius: 0;
                    border: 0;
                    background: none;
                }
            }
            
            .row:nth-child(odd){
                background-color: $gold-light;
            }

            .row:first-child{
                background-color: $primary;
                color: white;
            }
        }
    }
}

.off-line{
    #main{
        margin: 0;
        padding: 0;
        .overflow-hidden{
            height: 650px;
            img{
                margin-top: -250px;;
            }
        }
        .container{
            h1{
                margin-top: 2rem;
            }
            p{
                font-size: 1.5rem;
                .tel{
                    background-color: $secondary;
                    color: white;
                    width: 300px;
                }
            }
        }
    }
}

.accueil{
    #main{
        padding: ($content-padding * 5) $content-padding;
        background: $gray-200;
    }
}

.estimation,
.alerte-e-mail,
.contact,
.mentions-legales,
.nos-honoraires,
.notre-agence,
.error{
    #main{
        padding: 12rem $content-padding $content-padding $content-padding;
        @include media-breakpoint-down(md) {
            padding: 0 $content-padding $content-padding $content-padding;
        }
        @include media-breakpoint-down(sm) {
            padding: 0 $content-padding $content-padding $content-padding;
        }
        h1{
            text-align: center;
        }
    }
}

.transaction-detail{
    #main{
        background-color: $gray-200;
        .row-detail{
            .prix{
                span{
                    display: inline;
                    background-color: $gray-blue;
                    padding: 0.5rem 1rem;
                    font-weight: bold;
                    font-size: 1.3rem;
                    color: white;
                    box-shadow: $box-shadow;
                }
            }
            .infos{
                div{
                    font-size: 1rem;
                    i{
                        color: $secondary;
                    }
                }
            }
        }
    }
}