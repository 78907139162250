#subFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color: $pink-light;
    color: white;


    .content-info{
        box-shadow: $box-shadow;
        border: 1px solid white;
        margin: 5rem 3rem;
        padding: 3rem 2rem;
        background-color: $secondary;
        .col-title{
            h3{
                i{
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 4rem;
                }
                font-size: 2rem;
            }
        }
        .col-text{
            p{
                font-size: 4rem;
            }
        }
    }

    .row-title{
        h3{
            font-family: "Montserrat", sans-serif;
        }
    }
    .row-annonces{
        .col{
            .card{
                .card-body{
                    background-color: rgba(255,255,255,0.6);
                    h6{
                        color: black;
                        font-size: 0.9rem;
                        line-height: 1.7;
                    }
                }
                .overlay{
                    background-color: rgba(0,0,0,0.35);
                    opacity: 0;
                    transition:  all 0.5s;
                    a{
                        i{
                            font-size: 3rem;
                            color: rgba(255,255,255,0.35);
                        }
                    }
                }
            }
            .card:hover{
                .overlay{
                    opacity: 1;
                    transition:  all 0.5s;
                }
            }
            .card-plus{
                background-color: $gray-200;
                a{
                    i{
                        font-size: 3rem;
                        color: black;
                        display: block;
                    }
                    text-decoration: none;
                    color: black;
                }
            }
        }
        .card{
            box-shadow: $box-shadow;
            border: 1px solid white;
            .prix{
                span{
                    background-color: $primary;
                    color: white;
                    font-weight: 600;
                    padding: 0.25rem 0.5rem;
                }
            }
        }
    }

}