#topFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color:$gray;
    color: white;

    .row-urgence{
        margin-top: 3rem;
        margin-bottom: 3rem;
        h3{
            min-height: 2rem;
            font-size: 3rem;
            @include media-breakpoint-only(xs) {
                font-size: 1.5rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 1.5rem;
            }
            i{
                display: inline-block;
                margin-right: 0.5rem;
            }
        }
        p{
            font-size: 4rem;
            @include media-breakpoint-only(xs) {
                font-size: 2rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 2rem;
            }
            margin-top: 2rem;
            .tel{
                background-color: #ededed;
                color: black;
                padding: 0.75rem;
                box-shadow: $box-shadow;
            }
        }
    }

    .row-ges{
        .card{
            box-shadow: $box-shadow;
            p{
                color: black;
            }
        }
    }
    
    #formestimation-block{
        min-height: 600px;
        .estimation-text{
            i{
                font-size: 2rem;
            }
        }
        .estimation-form{
            .steps-container{
                .steps{
                    .step-item{
                        .step-num{
                            top: -20px;
                            background-color: $primary;
                            border-radius: 50%;
                            width: 45px;
                            height: 45px;
                            line-height: 1.5;
                            font-size: 1.5rem;
                            border:4px solid white;
                            margin: 0 4%;
                            transition: border 0.5s;
                        }
                    }
                    .step-item.active{
                        .step-num{
                            border:4px solid $secondary;
                            transition: border 0.5s;
                        }
                    }
    
                }
                .steps-line{
                    height: 3px;
                    background-color: #ededed;
                }
            }
    
            .tabs-container{
                .tab-2,
                .tab-3,
                .tab-4,
                .tab-5,
                .tab-6{
                    translate: 100%;
                    transition: all 0.5s;
                }
                .slide-left {
                    translate: -100%;
                    transition: all 0.5s;
                }
                .slide-middle {
                    translate: 0%;
                    transition: all 0.5s;
                }
                .slide-right {
                    translate: 100%;
                    transition: all 0.5s;
                }
                .btn-container{
                    a{
                        color: white;
                        font-size: 1.1rem;
                        text-decoration: none;
                        cursor: pointer;
                        i{
                            color: $secondary;
                        }
                    }
                }
                .field-container{
                    label{
                        color: black;
                    }
                    .error{
                        font-size: 0.7rem;
                        color: white;
                    }
                }
                .field-typebien{
                    .card{
                        border: 2px solid $gray-400;
                        box-shadow: $box-shadow;
                        transition: all 0.5s;
                        .card-body{
                            color: black;
                        }
                    }
                    .card.active{
                        border: 2px solid $secondary;
                        box-shadow: none;
                        transition: all 0.5s;
                    }
                    .card:hover{
                        cursor: pointer;
                    }
                }
            }
        }
    }

    #formalertee-mail-block{
        .row-alerte-email{
            form{
                label{
                    color: black;
                }
            }
        }
    }

}